import './index.scss';
import { Button, Spin, LinkExternalIcon, StatusIcon, Collapse, Label } from '@opsdti-global-component-library/amgen-design-system';
import { useState, useEffect } from 'react';
import { apiResponse, formatMonthDayYearToMMDDYYDash } from '../../utility/commonMethods';
import { toast } from 'react-toastify';
import ETLMonitoringComp from '../../components/etl-monitoring';

import BrandSvg from '../../assets/icons/svgs/brand-svg';
import FinanceSvg from '../../assets/icons/svgs/finance-svg';
import PipelineSvg from '../../assets/icons/svgs/pipeline-svg';
import SupplySvg from '../../assets/icons/svgs/supply-svg';
import ShieldSvg from '../../assets/icons/svgs/shield-svg';
import PeopleSvg from '../../assets/icons/svgs/people-svg';
import IntelligenceSvg from '../../assets/icons/svgs/intelligence-svg';
import { MultiSelect } from 'react-multi-select-component';

interface MapType {
  work_stream: string;
  jobs: {
    job_name: string;
    latest_runs: {
      run_id: string;
      run_start_time: string;
      run_end_time: string;
      job_status: string;
    }[];
  }[];
}

const ETLJobs: React.FC = () => {
  const [isLoading, setLoading] = useState(true);
  const [etlJobsData, setETLJobsData] = useState<Map<string, MapType[]>>();
  const [selectedWorkstream, setSelectedWorkstream] = useState<{ label: string; value: string }[]>([{ label: 'Admin', value: 'Admin' }]);

  useEffect(() => {
    if (etlJobsData === undefined) getETLJobsInfo();
  }, []);

  /*
   ** @description: API Call to get Etl Databricks Job Status
   **/
  const getETLJobsInfo = async () => {
    setLoading(true);
    const maxRuns = 5;
    const payload = {};
    try {
      const res = await apiResponse('post', 'get-etl-jobs-list', [], payload);
      if (res?.data?.status === 'SUCCESS') {
        if (res.data.data.length > 0) {
          for (let i = 0; i < res.data.data.length; i++) {
            for (let j = 0; j < res.data.data[i].jobs.length; j++) {
              //console.log('run_start_time', res.data.data[i].jobs[j].latest_runs);
              if (res.data.data[i].jobs[j].latest_runs.length < maxRuns) {
                //console.log('inside if', res.data.data[i].jobs[j].latest_runs.length);
                let arr = [];
                for (let k = 0; k < maxRuns - res.data.data[i].jobs[j].latest_runs.length; k++) {
                  arr.push({
                    run_id: '',
                    run_start_time: '',
                    run_end_time: '',
                    job_status: '',
                  });
                }
                res.data.data[i].jobs[j].latest_runs = [...res.data.data[i].jobs[j].latest_runs, ...arr];
              }
            }
          }

          const map = new Map<string, MapType[]>(res.data.data.map((job: MapType) => [job.work_stream?.trim(), job.jobs]));
          setETLJobsData(map);
        } else {
          toast.error('Jobs Data not available!!');
        }
      } else {
        console.log(res);
        toast.error('Failed to load!!');
      }
    } catch (error) {
      console.log(error);
      toast.error('Something Went Wrong!!');
    }
    setLoading(false);
  };

  return (
    <>
      {isLoading ? (
        <div className="adm-loader-container">
          <Spin />
        </div>
      ) : (
        <>
          {etlJobsData !== undefined ? (
            <div className="workstream-container">
              <div className="jobs-legend">
                <div className="legend-box">
                  <StatusIcon status="success" />
                  <div className="legend-text"> Job Succeed </div>
                </div>
                <div className="legend-box">
                  <StatusIcon status="error" />
                  <div className="legend-text"> Job Failed </div>
                </div>
                <div className="legend-box">
                  <StatusIcon status="missing" />
                  <div className="legend-text"> Job On-Hold </div>
                </div>
                <div className="legend-box">
                  <StatusIcon status="warning" />
                  <div className="legend-text"> Job In-Progress </div>
                </div>
                <div className="link-box">
                  <Button
                    text={'Grafana Cron Job Dashboard'}
                    onClick={() => {
                      window.open(process.env.REACT_APP_GRAFANA_LINK, '_blank');
                    }}
                    type="secondary"
                    icon={<LinkExternalIcon />}
                  />
                </div>
              </div>

              <>
                <div className="workstream-select-container">
                  <Label>Workstream: </Label>
                  <MultiSelect
                    className="multiselect etl-workstream-select"
                    options={Array.from(etlJobsData).map(([workstreamName, workstreamKey]) => ({ label: workstreamName, value: workstreamName }))}
                    onChange={(selected: any) => {
                      setSelectedWorkstream(selected as { label: string; value: string }[]);
                    }}
                    value={selectedWorkstream}
                    labelledBy="Select"
                  />
                </div>
                <Collapse
                  className="workstream-collapse"
                  defaultActiveKey={selectedWorkstream.map(workstream => workstream.value)}
                  activeKey={selectedWorkstream.map(workstream => workstream.value)}
                  onChange={key => {
                    const selected = Array.isArray(key) ? key.map(k => ({ label: k, value: k })) : [{ label: key, value: key }];
                    setSelectedWorkstream(selected);
                  }}
                  items={Array.from(etlJobsData).map(([workstreamName, workstreamKey]) => ({
                    leftIcon:
                      workstreamName === 'Admin' ? (
                        <ShieldSvg />
                      ) : workstreamName === 'Brand' ? (
                        <BrandSvg />
                      ) : workstreamName === 'Finance' ? (
                        <FinanceSvg />
                      ) : workstreamName === 'Pipeline' ? (
                        <PipelineSvg />
                      ) : workstreamName === 'Supply' ? (
                        <SupplySvg />
                      ) : workstreamName === 'People' ? (
                        <PeopleSvg />
                      ) : (
                        <IntelligenceSvg />
                      ),
                    key: workstreamName,
                    label: workstreamName,
                    children: <ETLMonitoringComp workstream={workstreamName} jobsData={etlJobsData.get(workstreamName)} refreshData={getETLJobsInfo} />,
                  }))}
                />
              </>
            </div>
          ) : null}
        </>
      )}
    </>
  );
};

export default ETLJobs;
