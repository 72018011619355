/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useEffect } from 'react';
import axios from 'axios';
import { hyphenToCamel } from './commonMethods';

export const authApiResponse = async (apiMethod: string, payload: any, endpoint: string) => {
  const oktaToken = getOktaToken();
  let finalUrl = process.env.REACT_APP_AUTH_LINK + endpoint;
  const res = await axios({
    method: apiMethod,
    url: finalUrl,
    data: payload,
    headers: {
      token: oktaToken,
    },
  });
  return res;
};


export const useUsersNames: any = (state: boolean) => {
  const [fullName] = useState<string>(JSON.parse(localStorage.getItem('sensing-okta-token') || '{}')?.idToken?.claims.name ?? '');
  const [firstName] = useState<string>(JSON.parse(localStorage.getItem('sensing-okta-token') || '{}')?.idToken?.claims?.name?.split(' ')[1] ?? '');
  const [emailId] = useState<string>(JSON.parse(localStorage.getItem('sensing-okta-token') || '{}')?.idToken?.claims?.email);
  return { fullName, firstName, emailId };
};

export const authorizationApi: (state: boolean) => any = (state: boolean) => {
  const { emailId } = useUsersNames(state);
  const [authorizationState, setAuthorizationState] = useState<any>({
    data: [],
    error: null,
    isLoading: false,
  });
  
  const fetchData = async () => {
    try {
      setAuthorizationState({
        ...authorizationState,
        isLoading: true,
      });
      if (emailId) {
        const payload = {
          "user_id": emailId.split('@')[0],
          "workstreams": [
            {
              "name": "admin",
              "get_component_auth": false,
              "get_sub_component_auth": false,
              "get_metric_auth": false,
              "get_page_auth": true,
              "get_data_auth": false,
              "get_vp_level_auth": false
            }
          ],
        };
        const res = await authApiResponse('post', payload, 'authorize_user');
        if (res && res.status === 200) {
          const pageAuth = res.data?.data?.[0]?.workstream_authorization.page_authorization
          const updatedData = {
            sensingAuthorization: {
              admin: res.data?.data?.[0]?.authorized,
              adminAuthorization: Object.entries(pageAuth).reduce((acc: any, [key, value]: [string, any]) => {
                acc[hyphenToCamel(key)] = value;
                return acc;
              }, {})
            }
          };
          setAuthorizationState({
            ...authorizationState,
            data: updatedData,
            isLoading: false,
          });
        }
      }
    } catch (error) {
      setAuthorizationState({
        ...authorizationState,
        error: error,
        isLoading: false,
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, [emailId]);
  return authorizationState;
};

export const getOktaDetails = () => {
  const oktaToken: any = localStorage.getItem('sensing-okta-token');
  const oktaTokenObj = JSON.parse(oktaToken);
  if (oktaTokenObj?.accessToken !== undefined) {
    return oktaTokenObj;
  }
  return null;
};

export const getOktaToken = () => {
  const oktaTokenDetails = getOktaDetails();
  if (oktaTokenDetails !== null) {
    return oktaTokenDetails.accessToken.accessToken;
  }
  return '';
};
