import './index.scss';
import { Button, Popover, StatusIcon } from '@opsdti-global-component-library/amgen-design-system';
import RunIcon from '../../assets/icons/run-button';
import { apiResponse } from '../../utility/commonMethods';
import { toast } from 'react-toastify';
import { getUserDetailsFromOktaToken } from '../../utility/commonMethods';
import { useContext } from 'react';
import { AppContext } from '../../contexts/app-context';

interface MapType {
  work_stream: string;
  jobs: {
    job_name: string;
    latest_runs: {
      run_id: string;
      run_start_time: string;
      run_end_time: string;
      job_status: string;
    }[];
  }[];
}
interface JobsPropsType {
  workstream: string;
  jobsData: MapType[] | undefined;
  refreshData: () => void;
}
const ETLMonitoringComp = (props: JobsPropsType) => {
  //console.log('workstrea', workstreamName, workstreamKey);
  const { authData } = useContext(AppContext);
  const isAuthorized = authData?.adminAuthorization?.etlJobTrigger;

  let columns = [
    {
      Header: 'Job Name',
      accessor: 'job_name',
    },
    {
      Header: 'Latest Runs',
      accessor: 'latest_runs',
    },
    {
      Header: 'Trigger',
      accessor: 'trigger',
    },
    {
      Header: 'Schedule',
      accessor: 'schedule',
    },
    {
      Header: 'Subscribe',
      accessor: 'subscribe',
    },
  ];

  const userEmailAddress = getUserDetailsFromOktaToken()?.email;

  columns = isAuthorized ? columns : columns.filter(column => column.accessor !== 'trigger');

  const triggerJob = async (jobID: string) => {
    try {
      const res = await apiResponse('post', 'etl-job-trigger', [jobID], []);
      console.log('res', res);
      if (res?.status === 200 && res.data.status === 'SUCCESS') {
        toast.success('Job triggered successfully');
        props.refreshData();
      } else {
        toast.error('Failed to trigger job');
      }
    } catch (error) {
      toast.error('Failed to trigger job');
    }
  };

  const subscribeJob = async (jobID: string, type: boolean) => {
    try {
      const payload = {
        job_id: jobID,
        subscribing: type,
        subscriber_to_update: userEmailAddress,
      };
      const res = await apiResponse('post', 'etl-job-subscribe', [], payload);
      if (res?.status === 200 && res.data.status === 'SUCCESS') {
        toast.success('Job subscribed/ unsubscribed successfully');
        props.refreshData();
      } else {
        toast.error('Failed to subscribe job');
      }
    } catch (error) {
      toast.error('Failed to subscribe job');
    }
  };

  return (
    <>
      <div className="workstream-box">
        {/* <div className="workstream-name">{props.workstream}</div> */}
        <div className="jobs-status">
          <table className="usage-metric-table etl-table">
            <thead>
              <tr>
                {columns.map((data: any, indx: any) => {
                  if (data.accessor === 'trigger' || data.accessor === 'subscribe' || data.accessor === 'latest_runs') {
                    return (
                      <th key={indx} className="etl-column">
                        {data.Header}
                      </th>
                    );
                  }
                  return <th key={indx}>{data.Header}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {props.jobsData?.map((data: any, indx: any) => {
                return (
                  <tr key={indx}>
                    {columns.map((colData: any, colIndx: any) => {
                      if (colData.accessor === 'latest_runs') {
                        return (
                          <td key={indx + colIndx} style={{ textAlign: 'center' }}>
                            <div className="latest-run-alignment">
                              {data.latest_runs
                                .map((run: any, runIndx: any) => (
                                  <Popover
                                    key={runIndx}
                                    content={
                                      <div>
                                        <p>Run ID: {run.run_id}</p>
                                        <p>Start Time: {run.run_start_time}</p>
                                        <p>End Time: {run.run_end_time}</p>
                                        <p>Status: {run.job_status}</p>
                                        {run.job_status === 'FAILED' && <p className="error-msg">Error: {run.error_message}</p>}
                                      </div>
                                    }
                                    title={'Run Details'}
                                  >
                                    {run.job_status === 'SUCCESS' ? (
                                      <StatusIcon status="success" />
                                    ) : run.job_status === 'FAILED' ? (
                                      <StatusIcon status="error" />
                                    ) : run.job_status === 'ON HOLD' ? (
                                      <StatusIcon status="missing" />
                                    ) : run.job_status === 'RUNNING' ? (
                                      <StatusIcon status="warning" />
                                    ) : (
                                      <div className="jobs no-status" key={runIndx}>
                                        —
                                      </div>
                                    )}
                                  </Popover>
                                ))
                                .reverse()}
                            </div>
                          </td>
                        );
                      } else if (colData.accessor === 'trigger') {
                        return (
                          <td key={indx + colIndx} style={{ paddingLeft: '60px' }}>
                            <Button
                              icon={<RunIcon fill={data.latest_run_status === 'RUNNING' ? '#D3D3D3' : 'var(--ops-gray-700)'} />}
                              type="link"
                              onClick={() => triggerJob(data.job_id)}
                              disabled={data.latest_run_status === 'RUNNING'}
                            />
                          </td>
                        );
                      } else if (colData.accessor === 'subscribe') {
                        if (data?.subscribers?.includes(userEmailAddress)) {
                          return (
                            <td key={indx + colIndx} style={{ paddingLeft: '30px' }}>
                              <Button text="Unsubscribe" type="link" danger onClick={() => subscribeJob(data.job_id, false)} />
                            </td>
                          );
                        }
                        else {
                          return (
                            <td key={indx + colIndx} style={{ paddingLeft: '35px' }}>
                              <Button text="Subscribe" type="link" onClick={() => subscribeJob(data.job_id, true)} />
                            </td>
                          );
                        }
                      } else {
                        return <td key={indx + colIndx}>{data[colData.accessor]}</td>;
                      }
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ETLMonitoringComp;
