interface Props {
  fill: string;
  classname?: string;
}
const ClosedTopicsIcon = ({ fill, classname }: Props) => {
  return (
    <svg viewBox="0 0 24 24" x="0px" y="0px" width="18" height="18" fill={fill}>
      <path d="M13 20H4a2 2 0 01-2-2V6c0-1.11.89-2 2-2h6l2 2h8a2 2 0 012 2v3.04c-.58-.44-1.25-.78-2-.93V8H4v10h9v2m10-2.7v3.5c0 .6-.6 1.2-1.3 1.2h-5.5c-.6 0-1.2-.6-1.2-1.3v-3.5c0-.6.6-1.2 1.2-1.2v-1.5c0-1.4 1.4-2.5 2.8-2.5s2.8 1.1 2.8 2.5V16c.6 0 1.2.6 1.2 1.3m-2.5-2.8c0-.8-.7-1.3-1.5-1.3s-1.5.5-1.5 1.3V16h3v-1.5z" />
    </svg>
  );
};

export default ClosedTopicsIcon;
