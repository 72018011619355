interface Props {
  fill: string;
  classname?: string;
}
const ProgramRiskIcon = ({ fill, classname }: Props) => {
  return (
    <svg viewBox="0 0 24 24" fill={fill} x="0px" y="0px" width="18" height="18">
      <path d="M11.001 10h2v5h-2zM11 16h2v2h-2z" />
      <path d="M13.768 4.2C13.42 3.545 12.742 3.138 12 3.138s-1.42.407-1.768 1.063L2.894 18.064a1.986 1.986 0 00.054 1.968A1.984 1.984 0 004.661 21h14.678c.708 0 1.349-.362 1.714-.968a1.989 1.989 0 00.054-1.968L13.768 4.2zM4.661 19L12 5.137 19.344 19H4.661z" />
    </svg>
  );
};

export default ProgramRiskIcon;
