import { useEffect, useRef, useState, useMemo } from 'react';
import './index.scss';
import { Metric_Header } from './ipt-dashboard-cols';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import moment from 'moment';
import { Empty } from '@opsdti-global-component-library/amgen-design-system';
import parse from 'html-react-parser';
import IptDashboardModel from '../../components/ipt-dashboard-create-model';
import { ColDef } from 'ag-grid-community';

interface IptTabProps {
  closeData: any[];
  triggerRefresh: any;
  userList: any[];
  getIPTAgendaComments: () => void;
}

const IPTCloseTab = (props: IptTabProps) => {
  const closedTopicGrid: any = useRef(null);
  const [closedCurrentTopics, setClosedCurrentTopics] = useState<any[]>([]);
  const [closedProgramRisk, setClosedProgramRisk] = useState<any[]>([]);
  const [showModel, setShowModel] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string>('');
  const [agendaId, setAgendaId] = useState<string>('');
  const [modalData, setModalData] = useState<any>({});

  const defaultColDef: any = useMemo<ColDef>(() => {
    return {
      resizable: true,
      initialWidth: 170,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      sortable: true,
      filter: true,
      filterParams: {
        maxNumConditions: 0,
      },
      cellRenderer: (params: any) => {
        if (params.colDef.field === 'agenda') {
          return (
            <span className="agenda-item-update" onClick={e => showDetail(e, params.data)}>
              {params.value}
            </span>
          );
        } else if (params.colDef.field === 'description') {
          return parse(params.value);
        } else {
          return params.value;
        }
      },
    };
  }, [props]);

  function showDetail(e: any, data: any) {
    setModalData(data);
    setModalType('closed');
    setTimeout(() => {
      setShowModel(true);
    }, 500);

    setAgendaId(data.agenda_id);
  }

  useEffect(() => {
    let closedData = props.closeData?.[0]?.current_topic?.map((item: any) =>
      Object.assign(item, { id: 'CLOSEDTOPICS' + item.agenda_id, submitted_on: moment(item.submitted_on).format('MM-DD-YYYY') }),
    );
    setClosedCurrentTopics(closedData);
    let closedProgramRisk = props.closeData?.[0]?.program_risks?.map((item: any) =>
      Object.assign(item, { id: 'CLOSEDPROGRAMRISK' + item.agenda_id, submitted_on: moment(item.submitted_on).format('MM-DD-YYYY') }),
    );
    setClosedProgramRisk(closedProgramRisk);
  }, [props]);

  return (
    <>
      {' '}
      <h2>IPT Topics</h2>
      {closedCurrentTopics.length > 0 ? (
        <div className="IPT-Section">
          <div className="detail-container-full ag-theme-quartz" ref={closedTopicGrid}>
            <AgGridReact columnDefs={Metric_Header} rowData={closedCurrentTopics} defaultColDef={defaultColDef} />
          </div>
        </div>
      ) : closedCurrentTopics.length === 0 ? (
        <div className="no-data-container">
          <Empty />
          No Data Available
        </div>
      ) : (
        <div className="no-data-container">
          <Empty />
          Failed to Load Data
        </div>
      )}
      <h2>Program Risks</h2>
      {closedProgramRisk.length > 0 ? (
        <div className="IPT-Section">
          <div className="detail-container-full ag-theme-quartz" ref={closedTopicGrid}>
            <AgGridReact columnDefs={Metric_Header} rowData={closedProgramRisk} defaultColDef={defaultColDef} />
          </div>
        </div>
      ) : closedProgramRisk.length === 0 ? (
        <div className="no-data-container">
          <Empty />
          No Data Available
        </div>
      ) : (
        <div className="no-data-container">
          <Empty />
          Failed to Load Data
        </div>
      )}
      <IptDashboardModel
        opened={showModel}
        onModelClose={() => {
          setShowModel(false);
        }}
        modalData={modalData}
        modalType={modalType}
        agendaId={agendaId}
        triggerRefresh={props.triggerRefresh}
        userList={props.userList}
        getIPTAgendaComments={props.getIPTAgendaComments}
      />
    </>
  );
};

export default IPTCloseTab;
