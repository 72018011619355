import { useEffect, useRef, useState, useMemo } from 'react';
import './index.scss';
import { Metric_Header } from './ipt-dashboard-cols';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import moment from 'moment';
import { Button, Empty } from '@opsdti-global-component-library/amgen-design-system';
import IptDashboardModel from '../../components/ipt-dashboard-create-model';
import parse from 'html-react-parser';
import { ColDef } from 'ag-grid-community';
interface IptTabProps {
  programRiskData: any[];
  triggerRefresh: any;
  userList: any[];
  getIPTAgendaComments: () => void;
}

const IPTProgramRisk = (props: IptTabProps) => {
  const programRiskGrid: any = useRef(null);
  const [programRiskData, setProgramRiskData] = useState<any[]>([]);

  const newTopicHeader = [
    ...Metric_Header.map(col => (col.headerName.toLowerCase() === 'agenda' ? { ...col, headerName: 'Program Risk' } : col)),
    {
      field: 'agenda_status',
      key: 'agenda_status',
      headerName: 'Risk Category',
      width: 150,
    },
  ];
  const [showModel, setShowModel] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string>('');
  const [agendaId, setAgendaId] = useState<string>('');
  const [modalData, setModalData] = useState<any>({});
  const defaultColDef: any = useMemo<ColDef>(() => {
    return {
      resizable: true,
      initialWidth: 170,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      sortable: true,
      filter: true,
      filterParams: {
        maxNumConditions: 0,
      },
      cellRenderer: (params: any) => {
        if (params.colDef.field === 'agenda') {
          return (
            <span className="agenda-item-update" onClick={e => updateAgenda(e, params.data)}>
              {params.value}
            </span>
          );
        } else if (params.colDef.field === 'description') {
          return parse(params.value);
        } else {
          return params.value;
        }
      },
    };
  }, [props]);

  function updateAgenda(e: any, data: any) {
    setModalData(data);
    setModalType('program_risk');
    setTimeout(() => {
      setShowModel(true);
    }, 500);
    setAgendaId(data.agenda_id);
  }

  useEffect(() => {
    let programRiskData = props.programRiskData?.map((item: any) =>
      Object.assign(item, { id: 'PROGRAMRISK' + item.agenda_id, submitted_on: moment(item.submitted_on).format('MM-DD-YYYY') }),
    );
    setProgramRiskData(programRiskData);
  }, [props]);

  return (
    <div className="IPT-Section program-risks">
      {props.programRiskData?.length > 0 ? (
        <>
          {/* <Button
            type="primary"
            text=" Update Program Risk"
            onClick={() => {
              setShowModel(true);
            }}
          ></Button> */}
          <div className="detail-container-full ag-theme-quartz" ref={programRiskGrid}>
            <AgGridReact columnDefs={newTopicHeader} rowData={programRiskData} defaultColDef={defaultColDef} />
          </div>
        </>
      ) : props.programRiskData?.length === 0 ? (
        <div className="no-data-container">
          <Empty />
          No Data Available
        </div>
      ) : (
        <div className="no-data-container">
          <Empty />
          Failed to Load Data
        </div>
      )}
      <IptDashboardModel
        opened={showModel}
        onModelClose={() => {
          setShowModel(false);
        }}
        modalData={modalData}
        modalType={modalType}
        agendaId={agendaId}
        triggerRefresh={props.triggerRefresh}
        userList={props.userList}
        getIPTAgendaComments={props.getIPTAgendaComments}
      />
    </div>
  );
};

export default IPTProgramRisk;
