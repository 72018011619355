import React, { useContext, useState, ReactNode } from 'react';
import { toast } from 'react-toastify';

import { IReleaseNoteItem, IReleaseNote, IUpdateReleaseNoteByStatusPayload } from '../release-note-interfaces';
import StatusTag from '../status-tags';
import { filterByDateRanges, filterReleaseNoteItemsByStatus, filterReleaseNoteItemsByWorkstream } from '../utils';
import './index.scss';

import { useReleaseNotesContext } from '../../../contexts/release-note-context';
import useConfirm from '../../../hooks/use-confirm';
import { AppContext } from '../../../contexts/app-context';

import {  MetricCard, MetricCardHeader, OpsButton, OpsTypography, Status } from '@gitlab-rtsensing/component-library';

import { apiResponse, formatMonthDayYearToMMDDYY } from '../../../utility/commonMethods';
import ConfirmModal from '../../confirm-modal';
import { APPROVED, DEFERRED, DRAFT, PENDING, PUBLISHED } from '../constants';
import { Spin } from '@opsdti-global-component-library/amgen-design-system';

interface ReleaseNotesListProps {
  viewNote: () => void;
}

const ReleaseNotesList: React.FC<ReleaseNotesListProps> = ({ viewNote }) => {
  const { releaseNotes, username, releaseStatuses, refetch, isRefetching, isLoading, filters, setInitialForm, isPublicView } = useReleaseNotesContext();

  const { authData } = useContext(AppContext);
  const userIsPM = authData?.adminAuthorization.releaseNotesPm;

  // Create a helper function to update the status of a release note
  async function updateReleaseNoteStatus(payload: IUpdateReleaseNoteByStatusPayload) {
    const { data } = await apiResponse('post', 'update-release-note-status', [], payload);
    return data;
  }

  const updateReleaseNotesByStatus = async (payload: IUpdateReleaseNoteByStatusPayload) => {
    try {
      const data = await updateReleaseNoteStatus(payload);

      if (data) {
        toast.success('Release note successfully updated.');
        refetch();
      } else {
        toast.error('An error occurred while updating release note.');
      }
    } catch (error) {
      console.error('Error updating release note:', error);
      toast.error('An unexpected error occurred. Please try again later.');
    }
  };

  const { confirm, confirmState, confirmFn } = useConfirm();

  const publishAllNotesByVersion = async (notes: IReleaseNoteItem[]) => {
    const choice = await confirm({
      title: 'Publish Release Version',
      description: `Are you sure you want to publish all of the release notes for v${notes[0].releaseVersionNumber} released on ${notes[0].releaseVersionDate}?`,
      confirmBtnLabel: 'Yes',
    });

    if (!choice) return;

    const { releaseNoteStatusId: approvedStatusId } = releaseStatuses.find(rs => rs.status === PUBLISHED) || {
      releaseNoteStatusId: 0,
    };

    const updates = notes
      .filter(note => note.status === APPROVED)
      .map(note => {
        const payload: IUpdateReleaseNoteByStatusPayload = {
          releaseNoteId: note.releaseNoteId,
          statusId: approvedStatusId,
          username: username,
        };
        return updateReleaseNotesByStatus(payload);
      });

    try {
      await Promise.all(updates);
    } catch (error) {
      console.error('Error publishing all notes by version:', error);
      toast.error('An unexpected error occurred. Please try again later.');
    }
  };

  function handleViewActions(note: IReleaseNoteItem) {
    setInitialForm(note.releaseNoteId);
    viewNote();
  }

  const renderNotes = (notes: IReleaseNote[], status: string) => {
    return notes.map((note, index) => {
      return (
        ([APPROVED, PUBLISHED].includes(status) && note.isGoNogo === 'No') ? <></> :
          <li className="note__container" key={index}>
            <OpsTypography variant="p1" elementTag="div" children={`• ${note.content}`} className="note__text" />
            <React.Fragment key="internal">{note.isPrivate ? <StatusTag status="internal" className="note__internal-tag" /> : null}</React.Fragment>
            <React.Fragment key="rapid-release">{note.isRapidrelease ? <StatusTag status="Rapid Release" className="rapid-release" /> : null}</React.Fragment>
            <React.Fragment key="go-note">{note.isGoNogo === 'Yes' && ![APPROVED, PUBLISHED].includes(status) ? <StatusTag status="Go" className="approved" /> : null}</React.Fragment>
            <React.Fragment key="no-go-note">{note.isGoNogo === 'No' && ![APPROVED, PUBLISHED].includes(status) ? <StatusTag status="No-Go" className="pending" /> : null}</React.Fragment>
          </li>
      );
    });
  };

  const renderReleaseNotes = () => {
    const filteredNotes = filterByDateRanges(releaseNotes, filters.dateRanges);
    const groupedReleaseNotes = filteredNotes.reduce((groups: { [key: string]: IReleaseNoteItem[] }, note) => {
      const key = `${note.releaseVersionNumber}-${note.releaseVersionDate}`;
      if (!groups[key]) {
        groups[key] = [];
      }
      groups[key].push(note);
      return groups;
    }, {});

    function getStatusValue(status: string): number {
      switch (status) {
        case PUBLISHED:
          return 1;
        case APPROVED:
          return 2;
        case DEFERRED:
          return 3;
        case PENDING:
          return 4;
        case DRAFT:
          return 5;
        default:
          return 6;
      }
    }

    function sortReleaseNotes(releaseNotes: IReleaseNoteItem[]): IReleaseNoteItem[] {
      return releaseNotes.sort((a, b) => {
        // Sort by status
        const statusComparison = getStatusValue(a.status) - getStatusValue(b.status);
        if (statusComparison !== 0) {
          return statusComparison;
        }

        // Sort by workstream
        return a.workstream.localeCompare(b.workstream);
      });
    }

    function renderDependency(teamString: string): ReactNode {
      let teamArray = teamString.split(',');
      teamArray = teamArray.filter(data => data.toUpperCase() !== 'NONE');
      return teamArray.map(team => <StatusTag status={team} className="dependency" />);
    }

    const releaseNoteGroups = Object.entries(groupedReleaseNotes).reverse();
    return releaseNoteGroups.map(([key, notes], index) => {
      notes = sortReleaseNotes(notes);
      notes = filterReleaseNoteItemsByStatus(notes, filters.statusFilters);
      notes = filterReleaseNoteItemsByWorkstream(notes, filters.workstreamFilters);
      const [releaseVersionNumber, releaseVersionDate] = key.split('-');
      const allPublished = notes.every(({ status }) => status === PUBLISHED);
      const noneApproved = notes.every(({ status }) => status !== APPROVED);

      if (notes.length === 0) {
        return null;
      }

      return (
        <div id={formatMonthDayYearToMMDDYY(releaseVersionDate).split('/').join('')} className="release-note-item">
          <MetricCard key={index}>
            <>
              <MetricCardHeader className="release-note-item__header">
                <span>
                  <OpsTypography variant="h3" variantWeight={700} elementTag="span" children={`v${releaseVersionNumber} `} />
                  <OpsTypography variant="h3" elementTag="span" children={`(Released ${formatMonthDayYearToMMDDYY(releaseVersionDate)})`} />
                </span>
                <div style={
                  { display: 'flex', alignItems: 'center' } as React.CSSProperties
                }>
                <OpsTypography variant="h3" variantWeight={700} elementTag="span" children={`Note: ${notes.length.toString()}`} />
                  {allPublished && !isPublicView ? (
                    <span style={{marginLeft: '10px'}}><StatusTag status={PUBLISHED}/></span>
                  ) : noneApproved || !userIsPM || isPublicView ? (
                    <></>
                  ) : (
                    <OpsButton
                      label="PUBLISH APPROVED NOTES"
                      type="primary"
                      className="publish-all-button"
                      onClick={() => {
                        publishAllNotesByVersion(notes);
                      }}
                    />
                  )}
                </div>
              </MetricCardHeader>
              {notes.map((note, indx) => (
                <div key={note.releaseNoteId} className="release-note-item__workstream-container">
                  <div className="release-note-item__workstream-header">
                    <div>
                      <OpsTypography
                        variant="p1"
                        variantWeight={700}
                        elementTag="span"
                        className="release-note-item__workstream-label"
                        children={note.workstream.toUpperCase()}
                      />
                      <React.Fragment key={indx + note.status}>{!isPublicView && <StatusTag status={note.status} />}</React.Fragment>
                      <React.Fragment key={indx + note.dependencyTeam}>
                        {note.dependencyTeam !== null &&
                          note.dependencyTeam !== '' &&
                          note.dependencyTeam?.split(',').filter((data: string) => data.toUpperCase() !== 'NONE').length > 0 ? (
                          <>
                            {' '}
                            {<span className="ops-fw-bold ops-fs-5">| Dependent WorkStream: </span>}
                            {renderDependency(note.dependencyTeam)}
                          </>
                        ) : null}
                      </React.Fragment>
                    </div>
                    <div className="release-note-item__actions">
                      {!isPublicView && <OpsButton label="ACTIONS" type="secondary" onClick={() => handleViewActions(note)} />}
                    </div>
                  </div>
                  <hr className="release-note-item__hr-tag" />
                  <div className="release-note-item__content-container">
                    <ul className="release-note-item__notes">{renderNotes(note.notes, note.status)}</ul>
                  </div>
                </div>
              ))}
            </>
          </MetricCard>
        </div>
      );
    });
  };

  return (
    <div className="release-notes-list">
      {renderReleaseNotes()}
      {isLoading && (
        <div className="release-notes-list__overlay">
          <Spin />
        </div>
      )}
      <ConfirmModal {...confirmState} onClose={() => confirmFn?.current?.(false)} onConfirm={() => confirmFn?.current?.(true)} />
    </div>
  );
};

export default ReleaseNotesList;
