import { stringToHTMLFormatter } from '../../utility/commonMethods';
import parse from 'html-react-parser';

const CustomTooltip = (props: any) => {
  const { value } = props;
  return <div className="custom-tooltip">{parse(value)}</div>;
};

export default CustomTooltip;

export const Metric_Header = [
  {
    field: 'agenda',
    headerName: 'Agenda',
    key: 'agenda',
    wrapText: true,
    width: 300,
    autoHeight: true,
    tooltipField: 'agenda',
  },
  {
    field: 'description',
    key: 'description',
    headerName: 'Description',
    wrapText: true,
    width: 550,
    autoHeight: true,
    tooltipComponent: CustomTooltip,
    tooltipValueGetter: (params: any) => {
      return stringToHTMLFormatter(params.value);
    },
  },
  {
    field: 'impacting_workstreams',
    key: 'impacting_workstreams',
    headerName: 'Impacting Workstream',
    width: 150,
  },
  {
    field: 'status',
    key: 'status',
    headerName: 'Status',
    width: 150,
  },
  {
    field: 'priority',
    key: 'priority',
    headerName: 'Priority',
    width: 150,
  },
  {
    field: 'submitted_by',
    key: 'submitted_by',
    headerName: 'Created By',
    width: 150,
  },
  {
    field: 'submitted_on',
    key: 'submitted_on',
    headerName: 'Created On',
    width: 150,
  },
  /*  {
     field: 'assigned to',
     key: 'assigned to',
     headerName: 'Assigned To',
   }, */
];
