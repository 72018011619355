import React, { useEffect, useState } from 'react';
import { Tabs, Button, useMessage, Popconfirm, Modal, Card } from '@opsdti-global-component-library/amgen-design-system';
import IPTOpenTab from './IPTOpenTab';
import IPTCloseTab from './IPTCloseTab';
import IPTProgramRisk from './IPTProgramRisk';
import { apiResponse } from '../../utility/commonMethods';
import OpenTopicsIcon from '../../assets/icons/open-topics';
import ProgramRiskIcon from '../../assets/icons/program-risk';
import ClosedTopicsIcon from '../../assets/icons/closed-topics';
import moment from 'moment';
import { IptContext } from '../../contexts/ipt-context';

const IptDashboard = () => {
  const [openData, setOpenData] = useState<any[]>([]);
  const [closeData, setCloseData] = useState<any[]>([]);
  const [programRiskData, setProgramRiskData] = useState<any[]>([]);
  const [openComments, setOpenComments] = useState<any[]>([]);
  const [momSummary, setMomSummary] = useState<any[]>([]);
  const fillColor = '#000';
  const [showMoreTxt, setShowMoreTxt] = useState('Show MOM');
  const [allSensingUser, setAllSensingUser] = useState<any[]>([]);
  const [userIdNameMap, setUserIdNameMap] = useState<Map<string, string>>(new Map());
  const { showMessage, contextHolder } = useMessage();

  const getIPTAgenda = async () => {
    try {
      const response = await apiResponse('get', 'get-ipt-agenda', [], []);
      if (response?.status === 200) {
        setOpenData(response?.data?.data?.[0]?.open);
        setCloseData(response?.data?.data?.[0]?.close);
        setProgramRiskData(response?.data?.data?.[0]?.open?.[0]?.program_risks);
      } else {
        setOpenData([]);
        setCloseData([]);
        setProgramRiskData([]);
      }
    } catch (error) {
      console.log('Error in getIPTAgenda', error);
    }
  };

  const getIPTAgendaComments = async () => {
    try {
      const response = await apiResponse('get', 'get-ipt-agenda-comments', [], []);
      if (response?.status === 200) {
        let responseData = response?.data?.data?.map((obj: any) => {
          return {
            ...obj,
            comments: obj.comments.map((comment: any) => {
              return {
                ...comment,
                user_id: userIdNameMap.get(comment.comment_by),
                isDayPassed: moment().diff(moment(comment.comment_date), 'days') > 1 ? true : false,
              };
            }),
          };
        });
        setOpenComments(responseData);
      } else {
        console.log('Error in getIPTAgendaComments', response);
      }
    } catch (error) {
      console.log('Error in getIPTAgenda', error);
    }
  };

  useEffect(() => {
    triggerRefresh();
  }, []);

  useEffect(() => {
    getIPTAgendaComments();
  }, [userIdNameMap]);

  const triggerRefresh = () => {
    getAllSensingUsers();
    getIPTAgenda();
    getMomSummary();
  };

  const sentMom = async () => {
    try {
      const response = await apiResponse('post', 'sent-ipt-mom', [moment().format('YYYY-MM-DD')], []);
      if (response?.data?.status === 'SUCCESS') {
        showMessage({ content: 'MOM has been sent successfully', type: 'success', duration: 5 });
      } else {
        showMessage({ content: response?.data?.data, type: 'error', duration: 5 });
      }
    } catch (error) {
      console.log('Error in sending MOM', error);
    }
  };

  const getMomSummary = async () => {
    try {
      const response = await apiResponse('get', 'get-mom-summary', [], []);
      if (response?.data?.status === 'SUCCESS') {
        let responseData = response?.data?.data?.map((obj: any) => {
          return {
            ...obj,
            sent_date: moment(obj.sent_date).format('MM-DD-YYYY'),
          };
        });
        setMomSummary(responseData);
      } else {
        showMessage({ content: 'Past MOM summary is not available', type: 'error', duration: 5 });
      }
    } catch (error) {
      console.log('Error in getMomSummary', error);
    }
  };

  const getAllSensingUsers = async () => {
    try {
      const response = await apiResponse('get', 'get-sensing-users', [], []);
      if (response?.data?.status === 'SUCCESS') {
        let userNameIdMap = new Map();
        let responseData = response?.data?.data?.map((obj: any) => {
          userNameIdMap.set(obj.user_name, obj.user_id);
          return obj;
        });
        setUserIdNameMap(userNameIdMap);
        setAllSensingUser(responseData);
      } else {
        showMessage({ content: 'Sensing User Data is not available', type: 'error', duration: 5 });
      }
    } catch (error) {
      console.log('Error in getting sensing Users', error);
    }
  };

  const onShowMore = () => {
    setShowMoreTxt(showMoreTxt === 'Show MOM' ? 'Hide MOM' : 'Show MOM');
  };

  return (
    <>
      <IptContext.Provider value={{ openComments, setOpenComments, allSensingUser }}>
        <div className="mom-section">
          {contextHolder}
          {momSummary.length > 0 ? (
            <Card className={showMoreTxt === 'Show MOM' ? 'mom-summary-card' : 'mom-summary-card show-more'}>
              <Button text={showMoreTxt} type="primary" className="show-more-btn" onClick={onShowMore} />
              {momSummary.map(obj => {
                return (
                  <div key={obj.sent_date} className="mom-summary">
                    <div className="mom-summary-date mom-separator">MOM Summary on {obj.sent_date}</div>
                    {obj.agenda_specific_summaries.map((obj: any) => (
                      <React.Fragment key={obj.agenda_name}>
                        <h3 className="agenda-name-heading">{obj.agenda_name}</h3>
                        <div className="agenda-summary">{obj.summary}</div>
                        <br />
                      </React.Fragment>
                    ))}
                  </div>
                );
              })}
            </Card>
          ) : null}
          <Popconfirm content="Are you sure you want to send MOM?" onCancel={() => {}} onConfirm={() => sentMom()}>
            <Button type="secondary" text="send mom"></Button>
          </Popconfirm>
        </div>
        <Tabs
          className=""
          items={[
            {
              children: (
                <IPTOpenTab openData={openData} triggerRefresh={triggerRefresh} userList={allSensingUser} getIPTAgendaComments={getIPTAgendaComments} />
              ),
              key: 'open',
              label: (
                <>
                  <OpenTopicsIcon fill={fillColor} />
                  Open Topics
                </>
              ),
            },
            {
              children: (
                <IPTProgramRisk
                  programRiskData={programRiskData}
                  triggerRefresh={triggerRefresh}
                  userList={allSensingUser}
                  getIPTAgendaComments={getIPTAgendaComments}
                />
              ),
              key: 'program_risks',
              label: (
                <>
                  <ProgramRiskIcon fill={fillColor} /> Program Risks
                </>
              ),
            },
            {
              children: (
                <IPTCloseTab closeData={closeData} triggerRefresh={triggerRefresh} userList={allSensingUser} getIPTAgendaComments={getIPTAgendaComments} />
              ),
              key: 'close',
              label: (
                <>
                  <ClosedTopicsIcon fill={fillColor} />
                  Closed Topics
                </>
              ),
            },
          ]}
        />
      </IptContext.Provider>
    </>
  );
};

export default IptDashboard;
