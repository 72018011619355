import { Button, TrashIcon, useMessage, Popconfirm, Empty } from '@opsdti-global-component-library/amgen-design-system';
import { useEffect, useState, useRef, useMemo } from 'react';
import './index.scss';
import IptDashboardModel from '../../components/ipt-dashboard-create-model';
import { Metric_Header } from './ipt-dashboard-cols';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { GetRowIdParams, GridApi, GridReadyEvent, RowDropZoneParams } from 'ag-grid-community';
import parse from 'html-react-parser';
import moment from 'moment';
import { ColDef } from '@ag-grid-community/core';
import { apiResponse, stringToHTMLFormatter } from '../../utility/commonMethods';
import { MouseEvent } from 'react';

interface IptTabProps {
  openData: any[];
  triggerRefresh: () => void;
  userList: any[];
  getIPTAgendaComments: () => void;
}

interface Comment {
  user_comment: string;
  initials: string;
  user_name: string;
  comment_id?: string;
  user_id?: string;
  isDayPassed?: boolean;
}

const IPTOpenTab = (props: IptTabProps) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [showModel, setShowModel] = useState<boolean>(false);
  const [newDailyTopics, setNewDailyTopics] = useState<any[]>([]);
  const [followUpsTopics, setFollowUpsTopics] = useState<any[]>([]);
  const newTopicHeader = [
    ...Metric_Header.map(col => (col.field === 'agenda' ? { ...col, rowDrag: true } : col)),
    { field: 'delete', headerName: '', width: 70, filter: false, sortable: false },
  ];
  const [modalData, setModalData] = useState<any>({});
  const [modalType, setModalType] = useState<string>('');
  const [agendaId, setAgendaId] = useState<string>('');
  const [lastDraggedRowDetail, setLastDraggedRowDetail] = useState<any>({});

  const gridApi_newtopic: any = useRef(null);
  const gridApi_followups: any = useRef(null);
  const [leftApi, setLeftApi] = useState<GridApi | null>(null);
  const [rightApi, setRightApi] = useState<GridApi | null>(null);
  const { showMessage, contextHolder } = useMessage();
  const getRowId = (params: GetRowIdParams) => String(params.data.id);
  //let lastDraggedRowDetail: { data?: any; leftApi?: GridApi; targetApi?: GridApi } = {};

  const onGridReady = (side: string, params: GridReadyEvent) => {
    if (side === '1') {
      setLeftApi(params.api);
    } else {
      setRightApi(params.api);
    }
  };

  useEffect(() => {
    if (rightApi && leftApi) {
      //addGridDropZone("2", rightApi);
      addGridDropZone('1', leftApi);
    }
  });

  const addGridDropZone = (side: string, api: GridApi) => {
    const dropSide = side === '1' ? '2' : '1';
    const dropZone: RowDropZoneParams = {
      getContainer: () => (dropSide === '2' ? gridApi_followups.current! : gridApi_newtopic.current!),
      onDragStop: dragParams => addRecordToGrid(dropSide.toLowerCase(), dragParams.node.data),
    };

    api.addRowDropZone(dropZone);
  };

  const addRecordToGrid = (side: string, data: any) => {
    // if data missing or data has no it, do nothing
    if (!data || data.id == null) {
      return;
    }

    const targetApi = side === '1' ? leftApi : rightApi;

    // do nothing if row is already in the grid, otherwise we would have duplicates
    const rowAlreadyInGrid = !!targetApi!.getRowNode(data.id);

    if (rowAlreadyInGrid) {
      console.log('not adding row to avoid duplicates in the grid');
      return;
    }

    let rowCountBefore = targetApi?.getDisplayedRowCount();
    targetApi!.applyTransaction({ add: [data] });

    let rowCountAfter = targetApi?.getDisplayedRowCount();
    if (rowCountBefore !== rowCountAfter) {
      //sourceApi!.applyTransaction({ remove: [data] });
      let originalData = { ...data };
      let followupData = Object.assign(data, { status: 'follow_up' });

      // for followup event
      setTimeout(() => {
        const rowsInTopicsGrid = !!leftApi?.getRowNode(data.id);
        if (rowsInTopicsGrid) {
          leftApi?.applyTransaction({ remove: [data] });
        }
        setLastDraggedRowDetail({
          data: originalData,
          leftApi: leftApi!,
          targetApi: targetApi!,
        });
        console.log('Row Added', targetApi!.getRowNode(data.id));
        updateAgenda(null, data);
      }, 500);
    }
  };

  const resetLastDraggedRow = () => {
    if (lastDraggedRowDetail) {
      lastDraggedRowDetail.leftApi?.applyTransaction({ add: [lastDraggedRowDetail.data] });
      lastDraggedRowDetail.targetApi?.applyTransaction({ remove: [lastDraggedRowDetail.data] });
      console.log('Row Reset', lastDraggedRowDetail.data);
      setLastDraggedRowDetail({});
    }
  };

  const showMoreDescription = (e: any) => {
    if (e.target.innerText === 'SHOW LESS') {
      e.target.innerText = 'SHOW MORE';
      e.target.parentNode.querySelector('.agenda-description-text').classList.remove('expand-description');
    } else {
      e.target.innerText = 'SHOW LESS';
      e.target.parentNode.querySelector('.agenda-description-text').classList.add('expand-description');
    }
  };

  const defaultColDef: any = useMemo<ColDef>(() => {
    return {
      resizable: true,
      initialWidth: 170,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      sortable: true,
      filter: true,
      filterParams: {
        maxNumConditions: 0,
      },
      cellRenderer: (params: any) => {
        if (params.colDef.field === 'agenda') {
          return (
            <span className="agenda-item-update" onClick={e => updateAgenda(e, params.data)}>
              {params.value}
            </span>
          );
        } else if (params.colDef.field === 'description' && params.value.length > 80) {
          return (
            <p className="agenda-description-custom-block">
              <span className="agenda-description-text">{parse(stringToHTMLFormatter(params.value))}</span>{' '}
              <span className="show-more-description" onClick={e => showMoreDescription(e)}>
                SHOW MORE
              </span>
            </p>
          );
        } else if (params.colDef.field === 'description' && params.value.length <= 80) {
          return parse(stringToHTMLFormatter(params.value));
        } else if (params.colDef.field === 'delete') {
          return (
            <Popconfirm content="Are you sure you want to delete this agenda?" onCancel={() => {}} onConfirm={() => deleteAgenda(params.data)}>
              <TrashIcon color="error" />
            </Popconfirm>
          );
        } else {
          return params.value;
        }
      },
    };
  }, [props]);

  function updateAgenda(e: any, data: any) {
    setModalData(data);
    setModalType('update');
    setTimeout(() => {
      setShowModel(true);
    }, 500);
    setAgendaId(data.agenda_id);
  }

  const deleteAgenda = async (data: any) => {
    try {
      const res = await apiResponse('post', 'delete-ipt-agenda', [data?.agenda_id], []);
      if (res?.data?.status === 'SUCCESS') {
        showMessage({ content: 'Agenda has been deleted successfully', type: 'success', duration: 5 });
        setNewDailyTopics([]);
        setFollowUpsTopics([]);
        props.triggerRefresh();
      } else {
        showMessage({ content: 'Deletion Failed, please try again', type: 'error', duration: 5 });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    let newDailyTopics = props.openData?.[0]?.daily_topic.new.map((item: any) =>
      Object.assign(item, { id: 'DAILYTOPICS' + item.agenda_id, submitted_on: moment(item.submitted_on).format('MM-DD-YYYY') }),
    );
    let newFollowUpsTopics = props.openData?.[0]?.daily_topic.follow_up.map((item: any) =>
      Object.assign(item, { id: 'FOLLOWUPS' + item.agenda_id, submitted_on: moment(item.submitted_on).format('MM-DD-YYYY') }),
    );
    setNewDailyTopics(newDailyTopics);
    setFollowUpsTopics(newFollowUpsTopics);
  }, [props.openData]);

  return (
    <div className="IPT-Section">
      {contextHolder}
      {props.openData?.length > 0 ? (
        <div className="daily-topics-container">
          <div className="current-topic-header">
            <h3>Current Topics</h3>
            <Button
              type="primary"
              text=" Create New Topic"
              onClick={() => {
                setModalType('create');
                setShowModel(true);
              }}
            ></Button>
          </div>
          <div className="detail-container ag-theme-quartz" ref={gridApi_newtopic}>
            <AgGridReact
              onGridReady={params => onGridReady('1', params)}
              rowDragManaged={true}
              columnDefs={newTopicHeader}
              rowData={newDailyTopics}
              getRowId={getRowId}
              suppressMoveWhenRowDragging={true}
              defaultColDef={defaultColDef}
            />
          </div>
          <div className="drop-col">
            <h3> Follow Ups</h3>
            <div className="detail-container ag-theme-quartz" ref={gridApi_followups}>
              <AgGridReact
                columnDefs={Metric_Header}
                rowData={followUpsTopics}
                onGridReady={params => onGridReady('2', params)}
                rowDragManaged={true}
                getRowId={getRowId}
                suppressMoveWhenRowDragging={true}
                defaultColDef={defaultColDef}
              />
            </div>
          </div>
        </div>
      ) : props.openData?.length === 0 ? (
        <div className="no-data-container">
          <Empty />
          No Data Available
        </div>
      ) : (
        <div className="no-data-container">
          <Empty />
          Failed to Load Data
        </div>
      )}
      <IptDashboardModel
        opened={showModel}
        onModelClose={() => {
          setShowModel(false);
          resetLastDraggedRow();
        }}
        modalData={modalData}
        modalType={modalType}
        agendaId={agendaId}
        triggerRefresh={props.triggerRefresh}
        userList={props.userList}
        getIPTAgendaComments={props.getIPTAgendaComments}
      />
    </div>
  );
};

export default IPTOpenTab;
