import React, { useState, useEffect, useMemo, useContext } from 'react';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { usageTrackingHeader } from './usageTrackingTable';
import '../index.scss';
import { apiResponse, filterMembers, rowSpanning } from '../../../utility/commonMethods';
import { toast } from 'react-toastify';
import { Spin } from '@opsdti-global-component-library/amgen-design-system';
import { AppContext } from '../../../contexts/app-context';
import { MetricProps, RowSpanType } from '../usage-metric-interface';
import useRenderSpanning from '../../../hooks/use-render-spanning';
import YRotate from '../../../assets/images/YRotate.png';
import NotificationsCount from './notificationCount';
import AgGridTable from '../../../utility/agGridTable';
import { FileDownloadIcon } from '@opsdti-global-component-library/amgen-design-system';
import { ExpandIcon, CloseIcon } from '@opsdti-global-component-library/amgen-design-system';

const Notification = (props: MetricProps) => {
  const columns = useMemo(() => usageTrackingHeader, []);
  const [usageTrackingData, setUsageTrackingData] = useState(Array<RowSpanType>);
  const [usageTrackingOriginal, setUsageTrackingOriginal] = useState<[]>([]);
  const [isLoading, setLoading] = useState(true);
  const { userFilter } = useContext(AppContext);
  const [tableMsg, setTableMsg] = useState('No Data Available');
  // const tableData = useRenderSpanning(usageTrackingData, columns, props.date, 'notification', true);
  const [flipButtonText, setflipButtonText] = useState('Go To User Details');
  const [showExpandOrClose, setShowExpandOrClose] = useState(false);
  const [triggerDownload, setTriggerDownload] = useState(false);

  useEffect(() => {
    if (props.clearData) {
      setUsageTrackingData([]);
      setUsageTrackingOriginal([]);
      getUsageTrackingData();
    }
  }, [props.clearData]);

  useEffect(() => {
    if (userFilter.filter) {
      filterGroupValue([], false, userFilter);
    } else {
      filterGroupValue(usageTrackingOriginal, false, { userId: '', fullname: '', filter: false });
    }
  }, [userFilter, props.includeCoreMember, props.userGroupValue]);

  useEffect(() => {
    if (usageTrackingOriginal.length === 0) return;
    filterGroupValue([], false, { userId: '', fullname: '', filter: false });
  }, [props.userGroupValue, props.includeCoreMember, usageTrackingOriginal]);

  const filterGroupValue = (dataArray: [], localSearch: Boolean, userFilter: { userId: string; fullname: string; filter: Boolean }) => {
    let filteredData = [];
    if (dataArray.length === 0 && !localSearch) {
      filteredData = filterMembers(usageTrackingOriginal, props.userGroupValue, props.includeCoreMember);
    } else {
      filteredData = filterMembers(dataArray, props.userGroupValue, props.includeCoreMember);
    }

    if (userFilter.userId !== '') {
      //In case of Bar Click
      filteredData = filteredData.filter((obj: { userid: string }) => obj.userid === userFilter.userId);
      if (filteredData.length === 0) {
        setTableMsg(`No Data Available for  ${userFilter.fullname}`);
      }
    } else if (localSearch && dataArray.length === 0) {
      //Incase of local Search
      setTableMsg(`No Data Available`);
    } else if (filteredData.length === 0) {
      //Incase of GroupValue search
      setTableMsg(`No Data Available for  ${props.userGroupValue}`);
    }
    setUsageTrackingData(rowSpanning(filteredData));
  };

  const getUsageTrackingData = async () => {
    setLoading(true);

    const payload = {
      metric_id: '12',
      date_type: props.date.type,
      to_date: props.date.toDate,
      fr_date: props.date.fromDate,
    };
    try {
      const res = await apiResponse('post', 'get-usage-metric-data', [], payload);
      if (res?.data?.status === 'SUCCESS') {
        if (res?.data?.data?.user_metric) {
          if (res.data.data.user_metric.length > 0) {
            setUsageTrackingOriginal(res.data.data.user_metric);
            filterGroupValue(res.data.data.user_metric, false, { userId: '', fullname: '', filter: false }); //Filter By User Group & SetUsageTrackingData
            props.setClearData(false);
          }
        }
      } else {
        console.log(res);
        toast.error('Notification: Failed to load!!');
      }
    } catch (error) {
      console.log(error);
      toast.error('Notification: Something Went Wrong!!');
    }
    setLoading(false);
  };

  const flipButtonClick = () => {
    let flipPanel = document.querySelector('.visitor-log .flip-panel');

    if (flipPanel?.classList.contains('rotate')) {
      flipPanel?.classList.remove('rotate');
      setflipButtonText('Go To User Details');
    } else {
      flipPanel?.classList.add('rotate');
      setflipButtonText('Go To Notification Details');
    }
  };

  // useEffect(() => {
  //   if (!showExpandOrClose) {
  //     document.getElementById('notifications')?.classList.add('container-width-100');
  //   } else {
  //     document.getElementById('notifications')?.classList.remove('container-width-100');
  //   }
  // }, [showExpandOrClose]);

  return (
    <div className="usage-metric visitor-log">
      <div className="metric-header no-left-field">
        {flipButtonText === 'Go To User Details' && (
          <div className="non-filter-note ">
            <p>* This metrics is not filterable by User or User-group</p>
          </div>
        )}
        <div className="flip-button" onClick={() => flipButtonClick()}>
          <span>{flipButtonText}</span>
          <img src={YRotate} alt="flip-icon"></img>
        </div>
      </div>
      <div className="flip-panel">
        <div className="flip-panel-inner">
          <div className="front-panel">
            <NotificationsCount
              date={props.date}
              clearData={props.clearData}
              setClearData={props.setClearData}
              userGroupValue={props.userGroupValue}
              includeCoreMember={props.includeCoreMember}
            />
          </div>
          <div className="back-panel">
            <div className="metric-header">
              <div>
                <label className="form-label">{props.date.displayValue}</label>
              </div>
              <div className="filters ">
                <FileDownloadIcon height={28} width={28} onClick={() => {
                  setTriggerDownload(true);
                }} />
                {showExpandOrClose ? (
                  <span
                    onClick={() => {
                      setShowExpandOrClose(false);
                    }}
                    className="expand-collapse-icon"
                  >
                    <ExpandIcon className="expand-collapse-icon" /> {/*This classname somehow not rendering*/}
                  </span>
                ) : (
                  <span
                    onClick={() => {
                      setShowExpandOrClose(true);
                    }}
                  >
                    <CloseIcon className="expand-collapse-icon" />
                  </span>
                )}
              </div>
            </div>

            {isLoading ? (
              <div className="adm-loader-container">
                <Spin />
              </div>
            ) : (
              <div className="table-container usage-metric-container">
                {usageTrackingData.length === 0 ? (
                  <p className="table-msg">{tableMsg}</p>
                ) : (
                  <AgGridTable
                    rowData={usageTrackingData}
                    columnDefs={columns}
                    matamoDate={props.date.matamoDate}
                    matamoType={props.date.matamoType}
                    triggerdownload={triggerDownload}
                    setTriggerDownload={setTriggerDownload}
                    onExpandableClosed={() => setShowExpandOrClose(false)}
                    expandable={showExpandOrClose}
                    tableName="Notification"
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Notification;
