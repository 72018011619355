import { createContext } from 'react';

type AuthorizationDataResponse = {
  audit: boolean;
  levelOneApprover: boolean;
  levelTwoApprover: boolean;
  features: boolean;
  usageMetric: boolean;
  releaseNotes: boolean;
  releaseNotesPm: boolean;
  releaseNotesAdmin: boolean;
  userAudit: boolean;
};

export interface IAppState {
  authData?: AuthorizationDataResponse | null;
  setAuthData: (authData: AuthorizationDataResponse | null) => void;
  userFilter?: any | '';
  setUserFilter: (accessRequestSuccessful: any) => void;
  accessRequestSuccessful?: any | false;
  setAccessRequestSuccessful: (accessRequestSuccessful: any) => void;
  constInfo: Map<string, string>;
  setConstInfo: (constData: Map<string, string>) => void;
  userGroups: string[];
  setUserGroups: (groups: string[]) => void;
  usageMetricFilter: string;
  setUsageMetricFilter: (filter: string) => void;
}

export const AppContext = createContext<any>(null);
