import React from 'react';

type SvgProps = {
  className?: string;
};

const IntelligenceSvg = (props: SvgProps) => {
  const { className } = props;
  return (
    <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.2467 5.73328C13.197 5.61909 13.1163 5.52109 13.0138 5.4504C12.9113 5.3797 12.7911 5.33913 12.6667 5.33328H9.61333L10.46 2.17328C10.487 2.07439 10.4908 1.97062 10.4711 1.87003C10.4515 1.76944 10.4089 1.67474 10.3467 1.59328C10.2846 1.5126 10.2048 1.44722 10.1135 1.40215C10.0222 1.35708 9.92181 1.33352 9.82 1.33328H5.15333C5.00282 1.32812 4.855 1.37408 4.73395 1.46366C4.61289 1.55324 4.52573 1.68117 4.48667 1.82661L2.7 8.49328C2.67303 8.59216 2.66923 8.69593 2.68889 8.79652C2.70854 8.89711 2.75113 8.99182 2.81333 9.07328C2.87612 9.15485 2.95695 9.22077 3.04948 9.26588C3.14201 9.31099 3.24373 9.33406 3.34667 9.33328H5.92667L4.72 13.8266C4.68046 13.9715 4.69101 14.1255 4.74993 14.2636C4.80886 14.4018 4.9127 14.516 5.04464 14.5878C5.17658 14.6595 5.32888 14.6846 5.47687 14.659C5.62486 14.6334 5.75986 14.5585 5.86 14.4466L13.1267 6.44661C13.2132 6.35187 13.2705 6.23417 13.2918 6.10763C13.3131 5.9811 13.2974 5.85112 13.2467 5.73328ZM6.72 11.5199L7.43333 8.85328C7.4603 8.75439 7.4641 8.65062 7.44444 8.55003C7.42479 8.44944 7.3822 8.35474 7.32 8.27328C7.25789 8.1926 7.17814 8.12722 7.08684 8.08215C6.99555 8.03708 6.89514 8.01352 6.79333 8.01328H4.23333L5.66 2.66661H8.94667L8.1 5.82661C8.07282 5.92731 8.06964 6.03299 8.09074 6.13514C8.11183 6.2373 8.15659 6.33308 8.22144 6.41478C8.28628 6.49648 8.36939 6.56183 8.46409 6.60557C8.55878 6.64931 8.66242 6.67021 8.76667 6.66661H11.1467L6.72 11.5199Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IntelligenceSvg;
