import { useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { Security, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { AppContext } from '../../contexts/app-context';
import PageLayout from '../page-layout';
import UsageMetric from '../../page/usage-metric';
import AccessRequestPage from '../../page/access-request';
import UserAudit from '../../page/user-audit';
import { RequiredAuth } from '../secure-route';
import UnauthorizedPage from '../../page/unauthorize-page';
import { ToastContainer } from 'react-toastify';
import ReleaseNotesPage from '../../page/release-notes-page';
import AccessLogs from '../../page/access-logs';
import ETLJobs from '../../page/etl-monitoring';
import DataElementsInventory from '../../page/data-elements-inventory';
import ConfigAPIUpdate from '../../page/config-update-page';
import RefreshBrand from '../../page/refresh-brand';
import SensingGptUserFeedback from '../../page/sensing-gpt-user-feedback';
import PeopleMapping from '../../page/people-mapping';
import IptDashboard from '../../page/ipt-dashboard';

export const AdminRoutes = () => {
  const navigate = useNavigate();
  const [authData, setAuthData] = useState<any>(null);
  const [accessRequestSuccessful, setAccessRequestSuccessful] = useState<any>(null);
  const [userFilter, setUserFilter] = useState({ userId: '', filter: false });
  const [constInfo, setConstInfo] = useState(new Map());
  const [userGroups, setUserGroups] = useState<string[]>([]);
  const [usageMetricFilter, setUsageMetricFilter] = useState<string>('');
  const paths = [
    'admin',
    'usage-metric',
    'user-audit',
    'sensing-access-request',
    'access-logs',
    'release-notes',
    'etl-monitoring',
    'data-elements-inventory',
    'sensing-constants',
    'refresh-brand',
    'gpt-feedback',
    'vp-mapping',
    'ipt-dashboard',
  ]; //Order shouldn't change

  const oktaAuthConfig = new OktaAuth({
    issuer: process.env.REACT_APP_OKTA_ISSUER_LINK as string,
    clientId: process.env.REACT_APP_OKTA_CLIENT_ID as string,
    redirectUri: window.location.origin + '/implicit/callback',
    scopes: ['openid', 'profile', 'email', 'offline_access'],
    pkce: true,
    responseType: 'code',
    tokenManager: {
      expireEarlySeconds: 120,
      autoRenew: true,
      storageKey: 'sensing-okta-token',
    },
  });

  const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <>
      <AppContext.Provider
        value={{
          authData,
          setAuthData,
          userFilter,
          setUserFilter,
          accessRequestSuccessful,
          setAccessRequestSuccessful,
          constInfo,
          setConstInfo,
          userGroups,
          setUserGroups,
          usageMetricFilter,
          setUsageMetricFilter,
        }}
      >
        <PageLayout>
          <Security oktaAuth={oktaAuthConfig} restoreOriginalUri={restoreOriginalUri}>
            <Routes>
              <Route element={<RequiredAuth />}>
                <Route
                  path={`/${paths[0]}`}
                  element={
                    <>{authData?.adminAuthorization?.usageMetric ? <UsageMetric /> : authData === null ? '' : <UnauthorizedPage feature={`${paths[1]}`} />}</>
                  }
                ></Route>
              </Route>
              <Route element={<RequiredAuth />}>
                <Route
                  path={`${'/admin/' + paths[1]}`}
                  element={
                    <>{authData?.adminAuthorization?.usageMetric ? <UsageMetric /> : authData === null ? '' : <UnauthorizedPage feature={`${paths[1]}`} />}</>
                  }
                ></Route>
              </Route>
              <Route element={<RequiredAuth />}>
                <Route
                  path={`${'/admin/' + paths[2]}`}
                  element={
                    <>
                      {authData?.adminAuthorization?.userAudit || authData?.adminAuthorization?.userAuditView ? (
                        <UserAudit />
                      ) : authData === null ? (
                        ''
                      ) : (
                        <UnauthorizedPage feature={`${paths[2]}`} />
                      )}
                    </>
                  }
                ></Route>
              </Route>
              <Route element={<RequiredAuth />}>
                <Route
                  path={`${'/admin/' + paths[3]}`}
                  element={
                    <>
                      {authData?.adminAuthorization?.sensingAccessRequest ? (
                        <AccessRequestPage />
                      ) : authData === null ? (
                        ''
                      ) : (
                        <UnauthorizedPage feature={`${paths[3]}`} />
                      )}
                    </>
                  }
                ></Route>
              </Route>
              <Route element={<RequiredAuth />}>
                <Route
                  path={`${'/admin/' + paths[4]}`}
                  element={
                    <>{authData?.adminAuthorization?.accessLog ? <AccessLogs /> : authData === null ? '' : <UnauthorizedPage feature={`${paths[4]}`} />}</>
                  }
                ></Route>
              </Route>
              <Route element={<RequiredAuth />}>
                <Route
                  path={`${'/admin/' + paths[5]}`}
                  element={
                    <>
                      {authData?.adminAuthorization?.releaseNotes ? (
                        <ReleaseNotesPage />
                      ) : authData === null ? (
                        ''
                      ) : (
                        <UnauthorizedPage feature={`${paths[5]}`} />
                      )}
                    </>
                  }
                ></Route>
              </Route>
              <Route element={<RequiredAuth />}>
                <Route
                  path={`${'/admin/' + paths[6]}`}
                  element={
                    <>{authData?.adminAuthorization?.etlMonitoring ? <ETLJobs /> : authData === null ? '' : <UnauthorizedPage feature={`${paths[6]}`} />}</>
                  }
                ></Route>
              </Route>
              <Route element={<RequiredAuth />}>
                <Route
                  path={`${'/admin/' + paths[7]}`}
                  element={
                    <>
                      {authData?.adminAuthorization?.dataElementsInventory ? (
                        <DataElementsInventory />
                      ) : authData === null ? (
                        ''
                      ) : (
                        <UnauthorizedPage feature={`${paths[7]}`} />
                      )}
                    </>
                  }
                ></Route>
              </Route>
              <Route element={<RequiredAuth />}>
                <Route
                  path={`${'/admin/' + paths[8]}`}
                  element={
                    <>
                      {authData?.adminAuthorization?.sensingConstants ? (
                        <ConfigAPIUpdate />
                      ) : authData === null ? (
                        ''
                      ) : (
                        <UnauthorizedPage feature={`${paths[8]}`} />
                      )}
                    </>
                  }
                ></Route>
              </Route>
              <Route element={<RequiredAuth />}>
                <Route
                  path={`${'/admin/' + paths[9]}`}
                  element={
                    <>{authData?.adminAuthorization?.brandRefresh ? <RefreshBrand /> : authData === null ? '' : <UnauthorizedPage feature={`${paths[9]}`} />}</>
                  }
                ></Route>
              </Route>
              <Route element={<RequiredAuth />}>
                <Route
                  path={`${'/admin/' + paths[10]}`}
                  element={
                    <>
                      {authData?.adminAuthorization?.gptFeedback ? (
                        <SensingGptUserFeedback />
                      ) : authData === null ? (
                        ''
                      ) : (
                        <UnauthorizedPage feature={`${paths[10]}`} />
                      )}
                    </>
                  }
                ></Route>
              </Route>
              <Route element={<RequiredAuth />}>
                <Route
                  path={`${'/admin/' + paths[11]}`}
                  element={
                    <>
                      {authData?.adminAuthorization?.peopleVpMapping ? (
                        <PeopleMapping />
                      ) : authData === null ? (
                        ''
                      ) : (
                        <UnauthorizedPage feature={`${paths[11]}`} />
                      )}
                    </>
                  }
                >
                  {' '}
                </Route>
              </Route>
              <Route element={<RequiredAuth />}>
                <Route
                  path={`${'/admin/' + paths[12]}`}
                  element={
                    <>{authData?.adminAuthorization?.usageMetric ? <IptDashboard /> : authData === null ? '' : <UnauthorizedPage feature={`${paths[12]}`} />}</>
                  }
                ></Route>
              </Route>
              <Route path="/implicit/callback" element={<LoginCallback />} />
            </Routes>
          </Security>
        </PageLayout>
      </AppContext.Provider>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};
// };
export default AdminRoutes;
