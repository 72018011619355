import { Modal, Label, Input, Select, Button, DatePicker, useMessage, Spin, PlusCircleIcon } from '@opsdti-global-component-library/amgen-design-system';
import { useEffect, useState } from 'react';
import './index.scss';
import tinymce from 'tinymce/tinymce';
import 'tinymce/plugins/link';
import 'tinymce/themes/silver';
// DOM model
import 'tinymce/models/dom/model';
// Toolbar icons
import 'tinymce/icons/default';
// Editor styles
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/plugins/lists'; // for bullet and numbered lists
import 'tinymce/plugins/insertdatetime'; // Import the insertdatetime plugin
import { getUserDetailsFromOktaToken, stringToHTMLFormatter } from '../../utility/commonMethods';
import { apiResponse } from '../../utility/commonMethods';
import parse, { htmlToDOM } from 'html-react-parser';
import { useContext } from 'react';
import { IptContext } from '../../contexts/ipt-context';
interface IptDashboardCreateModelProps {
  opened: boolean;
  onModelClose: () => void;
  modalData?: any;
  modalType?: string;
  triggerRefresh?: () => void;
  updateAgenta?: boolean;
  userList: any[];
  getIPTAgendaComments?: () => void;
  agendaId?: string;
}
interface Comment {
  user_comment: string;
  initials: string;
  user_name: string;
  comment_id?: string;
  user_id?: string;
  isDayPassed?: boolean;
}

const IptDashboardModel = (props: IptDashboardCreateModelProps) => {
  const { opened, onModelClose } = props;
  const { showMessage, contextHolder } = useMessage();
  const { openComments, allSensingUser } = useContext(IptContext);

  const page = [
    { label: 'Home', value: 'Home' },
    { label: 'Brand', value: 'Brand' },
    { label: 'People', value: 'People' },
    { label: 'Pipeline', value: 'Pipeline' },
    { label: 'Supply', value: 'Supply' },
    { label: 'Manufacturing', value: 'Manufacturing' },
    { label: 'Finance', value: 'Finance' },
    { label: 'Prioritized Agenda', value: 'Prioritized Agenda' },
    { label: 'External', value: 'External' },
    { label: 'Admin', value: 'Admin' },
  ];

  const statusItem =
    props.modalType === 'program_risk'
      ? [
          { label: 'Open', value: 'open' },
          { label: 'Close', value: 'close' },
        ]
      : [
          { label: 'Open', value: 'open' },
          { label: 'Follow-up', value: 'follow_up' },
          { label: 'Close', value: 'close' },
        ];

  const priorityItem = [
    { label: 'High', value: 'high' },
    { label: 'Medium', value: 'medium' },
    { label: 'Low', value: 'low' },
  ];

  const agendaStatusOption = [
    { label: 'Owned', value: 'OWNED' },
    { label: 'Mitigated', value: 'MITIGATED' },
    { label: 'Resolved', value: 'RESOLVED' },
    { label: 'Accepted', value: 'ACCEPTED' },
  ];

  const [agenda, setAgenda] = useState('');
  const [status, setStatus] = useState('open');
  const [agendaStatus, setAgendaStatus] = useState('OWNED');
  const [impactingWorkstream, setImpactingWorkstream] = useState<string[]>([]);
  const [priority, setPriority] = useState('');
  const [assignedTo, setAssignedTo] = useState('');

  const [followUpDate, setFollowUpDate] = useState('');
  const [existingComments, setExistingComments] = useState<any[]>([]);
  const userInfo = getUserDetailsFromOktaToken();
  const [isLoading, setIsLoading] = useState(false);
  const [commentLoader, setCommentLoader] = useState(false);
  const isDisabled = props.modalType === 'program_risk' || props.modalType === 'closed';
  const modalHeight = 900;
  const currentUserId = userInfo.email.split('@')[0];

  useEffect(() => {
    if (opened) {
      // Remove existing editors
      tinymce.remove();

      /*  tinymce.PluginManager.add('userTagging', function (editor, url) {
        // Add a button that opens a window
        editor.ui.registry.addButton('userTagging', {
          text: '@',
          icon: '',
          onAction: function () {
            // Open window with a text input
            editor.windowManager.open({
              title: 'Tag User',
              body: {
                type: 'panel',
                items: [{ type: 'input', name: 'username', label: 'Username' }],
              },
              onSubmit: function (e) {
                // Insert content when the window form is submitted
                const data = e.getData();
                editor.insertContent('@' + data.username);
              },
            });
          },
        });

        // Add a keyboard shortcut for tagging
        editor.on('keydown', function (e) {
          if (e.key === '@') {
            editor.execCommand('userTagging');
          }
        });
      }); */
      let commonConfig = {
        inline: false,
        menubar: false,
        plugins: ['link', 'lists'],
        toolbar: 'bold italic underline link h2 h3 h4 bullist numlist hr',
        insertdatetime_formats: ['%Y-%m-%d', '%H:%M:%S'], // Limit the date and time formats
        promotions: false,
        statusbar: false,
        contextmenu: false,
        content_style: 'body { font-family: Inter,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif; font-size: 14px; }',
        newlines: 'br',
      };
      // Initialize TinyMCE
      tinymce.init({
        ...commonConfig,
        ...{
          selector: '.tinymce-editor',
          contextmenu: false,
          height: 400,
          init_instance_callback: editor => {
            if (props.modalType === 'update' || props.modalType === 'program_risk' || props.modalType === 'closed') {
              editor.setContent(stringToHTMLFormatter(props.modalData?.description));
            } else {
              editor.setContent('');
            }
          },
        },
      });

      tinymce.init({
        ...commonConfig,
        ...{
          selector: '.comment-editor',
          height: 200,
          contextmenu: false,
          readonly: props.modalType === 'create',
        },
        disabled: props.modalType === 'closed',
        setup: editor => {
          editor.on('keydown', e => {
            if (e.key === 'Enter' && e.shiftKey) {
              e.preventDefault();
              submitComment();
            }
          });
        },
      });
    }
    // Cleanup function to remove TinyMCE instances when the modal is closed
    return () => {
      tinymce.remove();
    };
  }, [opened, props.modalType]);

  useEffect(() => {
    if (props.opened && (props.modalType === 'update' || props.modalType === 'program_risk' || props.modalType === 'closed')) {
      const filteredStatus = statusItem?.find(propsVal => propsVal.value === props.modalData['status']?.toLowerCase());
      const filteredPriority = priorityItem?.find(propsVal => propsVal.value === props.modalData['priority']?.toLowerCase());
      const filteredImpactingWorkstream = page?.filter(val => props.modalData['impacting_workstreams']?.includes(val.value));
      const filteredAgendaStatus = agendaStatusOption?.find(val => val.value === props.modalData['agenda_status']);

      setAgenda(props.modalData?.agenda);
      setStatus(filteredStatus ? filteredStatus.value : 'open');
      setAgendaStatus(filteredAgendaStatus ? filteredAgendaStatus.value : '');
      setImpactingWorkstream(filteredImpactingWorkstream.map(val => val.value));
      setPriority(filteredPriority ? filteredPriority.value : '');
      setAssignedTo(props.modalData?.assigned_to);

      pushComments();
    } else {
      setAgenda('');
      setStatus('open');
      setImpactingWorkstream([]);
      setPriority('');
      setAssignedTo('');
      setExistingComments([]);
      setAgendaStatus('');
    }
  }, [props]);

  const pushComments = () => {
    const userNameIdMap = new Map(allSensingUser.map((obj: { user_name: any; user_id: any }) => [obj.user_name, obj.user_id]));
    const comments = openComments.filter((item: any) => item.ipt_agenda_id === props.agendaId)?.flatMap((obj: any) => obj?.comments);
    const modelCommentsUpdated = comments?.map((obj: any) => {
      return {
        user_comment: obj.comments,
        initials: obj.initials,

        user_name: obj.comment_by || '',
        comment_id: obj.comment_id,
        user_id: userNameIdMap.get(obj.comment_by),
        isDayPassed: obj.isDayPassed,
      };
    });
    if (JSON.stringify(modelCommentsUpdated) !== JSON.stringify(existingComments)) {
      setExistingComments(modelCommentsUpdated);
      console.log(props.opened, 'modelCommentsUpdated', modelCommentsUpdated);
      tinymce.get(props.modalType + 'comment')?.setContent('');
    }
  };

  const submitTopic = async () => {
    if (props.modalType !== 'program_risk') {
      //program_risk is not required to save
      let objToSave = {
        description: tinymce.get('description')?.getContent(),
        ipt_agenda_item: agenda,
        priority: priority,
        status: status,
        agenda_type: status === 'follow_up' ? 'Followup' : 'New',
        impacted_workstream: impactingWorkstream.length > 0 ? impactingWorkstream.join() : '',
        created_by: userInfo?.userName.split(',').reverse().join(' ').trimStart(),
        followup_date: status === 'follow_up' ? followUpDate : '',
        assigned_to: assignedTo === '' ? userInfo?.email : assignedTo,
        agenda_status: props.modalType === 'program_risk' ? agendaStatus : '',
        ipt_agenda_id: props.modalType !== 'create' ? props.modalData?.agenda_id : 0,
        submitted_by: props.modalType === 'create' ? userInfo?.email : props.modalData?.submitted_by,
        update_by: props.modalType !== 'create' ? userInfo?.email : props.modalData?.update_by,
        active_index: true,
      };

      if (objToSave.ipt_agenda_item === '' || objToSave.status === '' || objToSave.description === '' || objToSave.priority === '') {
        showMessage({ content: 'Agenda Item, Description, Priority & Status are required fields', type: 'error', duration: 5 });
        return;
      }

      if (objToSave.status === 'follow_up' && followUpDate === '') {
        showMessage({ content: 'Follow Up Date is required when status is Follow Up', type: 'error', duration: 5 });
        return;
      }

      setIsLoading(true);
      try {
        const response = await apiResponse('post', 'insert-update-ipt-agenda', [], objToSave);
        if (response?.status === 200) {
          showMessage({ content: 'Agenda Item has been saved', type: 'success', duration: 5 });
          props.triggerRefresh && props.triggerRefresh();
          onModelClose();
        } else {
          showMessage({ content: 'Failed to save data', type: 'error', duration: 5 });
        }
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        showMessage({ content: 'Failed to save data', type: 'error', duration: 5 });
      }
    }
  };

  const submitComment = async () => {
    if (props.modalType === 'update' || props.modalType === 'program_risk') {
      if (tinymce.get(props.modalType + 'comment')?.getContent() !== '') {
        let commentObjToSave = {
          comments: tinymce.get(props.modalType + 'comment')?.getContent(),
          ipt_agenda_id: props.modalData?.agenda_id,
          create_by: userInfo?.userName,
          assigned_to: assignedTo,
        };
        try {
          const response = await apiResponse('post', 'insert-ipt-agenda-comments', [], commentObjToSave);
          if (response.status === 200) {
            showMessage({ content: 'Comments has been saved', type: 'success', duration: 5 });
            props.getIPTAgendaComments && props.getIPTAgendaComments();
          } else {
            showMessage({ content: 'Failed to save comments', type: 'error', duration: 5 });
          }
        } catch (e) {
          showMessage({ content: 'Failed to save comments', type: 'error', duration: 5 });
        }
      }
    }
  };

  const canCelModal = () => {
    try {
      const descriptionEditor = tinymce.get('description');
      const commentEditor = tinymce.get(props.modalType + 'comment');

      if (descriptionEditor) {
        tinymce.remove(descriptionEditor);
      }

      if (commentEditor) {
        tinymce.remove(commentEditor);
      }
    } catch (e) {
      console.log('Error in tinymce remove', e);
    }
    setAgenda('');
    setStatus('');
    setImpactingWorkstream([]);
    setPriority('');
    setAssignedTo('');
    setAgendaStatus('');
    onModelClose();
  };

  const removeComment = async (e: any, commentId: any) => {
    if (Number.isNaN(Number(commentId))) {
      return;
    }

    const commentItem = e.currentTarget.closest('.IPT-comment-item');
    try {
      e.target.innerHTML = '';

      if (commentItem) {
        commentItem.classList.add('deleting-comment');
      }
      const response = await apiResponse('post', 'remove-ipt-agenda-comments', [commentId], []);

      if (response?.status === 200) {
        showMessage({ content: 'Comment has been deleted', type: 'success', duration: 5 });
        if (commentItem && commentItem.parentNode) {
          //commentItem.parentNode.removeChild(commentItem);
          //setExistingComments(existingComments.filter(comment => comment.comment_id !== commentId));
          props.getIPTAgendaComments && props.getIPTAgendaComments();
        }
      } else {
        showMessage({ content: 'Failed to delete comment', type: 'error', duration: 5 });
      }
    } catch (error) {
      showMessage({ content: 'Failed to delete comment', type: 'error', duration: 5 });
    }

    if (commentItem && commentItem.parentNode) {
      e.target.innerHTML = 'X';
      commentItem.classList.remove('deleting-comment');
    }
  };

  return opened ? (
    <>
      {contextHolder}
      <Modal
        title={
          props.modalType === 'create'
            ? 'Create Topic'
            : props.modalType === 'program_risk'
            ? 'Program Risk'
            : props.modalType === 'closed'
            ? 'Closed Topic'
            : 'Update Topic'
        }
        open={opened}
        onCancel={canCelModal}
        width={props.modalType === 'create' ? 700 : 1200}
        height={modalHeight}
      >
        {isLoading ? (
          <div style={{ height: modalHeight + 'px' }}>
            <Spin size="large" />
          </div>
        ) : (
          <div>
            <div className="IPT-modal-comment-Section">
              <div className={props.modalType !== 'create' ? 'IPT-field-section' : 'IPT-modal-Sectiom create-mode'}>
                <div className="IPT-modal-Section">
                  <div className="IPT-modal-input-container">
                    <Label>Agenda Item</Label>
                    <Input disabled={isDisabled} className="ipt-model-input" id="agenda" onChange={e => setAgenda(e.target.value)} value={agenda} />
                  </div>
                  <div className="IPT-modal-input-container">
                    <Label>Description</Label>
                    {isDisabled ? (
                      <div aria-disabled={isDisabled} className="description-textarea">
                        {parse(props.modalData?.description)}
                      </div>
                    ) : (
                      <div className="ipt-model-input tinymce-editor" id="description" contentEditable={true} />
                    )}
                  </div>
                  {props.modalType === 'program_risk' && (
                    <div className="IPT-modal-input-container">
                      <Label>Risk Category</Label>
                      <Select
                        disabled={isDisabled}
                        options={agendaStatusOption}
                        placeholder="Select"
                        className="ipt-model-input"
                        onChange={(val: string) => setAgendaStatus(val)}
                        value={agendaStatus}
                      />
                    </div>
                  )}
                  <div className="IPT-modal-input-container">
                    <Label>Status</Label>
                    <Select
                      disabled={isDisabled}
                      options={statusItem}
                      placeholder="Select"
                      className="ipt-model-input"
                      onChange={(val: string) => setStatus(val)}
                      value={status}
                    />
                  </div>
                  <>
                    {status === 'follow_up' ? (
                      <div className="IPT-modal-input-container">
                        <Label>
                          Follow Up Date <sup> * </sup>
                        </Label>
                        <DatePicker
                          className="ipt-model-input"
                          showToday={true}
                          format={'YYYY-MM-DD'}
                          onChange={(date: any, dateString: any) => {
                            setFollowUpDate(dateString);
                          }}
                        />
                      </div>
                    ) : null}
                  </>
                  <div className="IPT-modal-input-container">
                    <Label>Impacting Workstream</Label>
                    <Select
                      disabled={isDisabled}
                      mode="tags"
                      options={page}
                      className="ipt-model-input"
                      onChange={(val: any) => setImpactingWorkstream(val)}
                      value={impactingWorkstream}
                    />
                  </div>
                  <div className="IPT-modal-input-container">
                    <Label>Priority</Label>
                    <Select
                      disabled={isDisabled}
                      options={priorityItem}
                      placeholder="Select"
                      className="ipt-model-input"
                      onChange={(val: string) => setPriority(val)}
                      value={priority}
                    />
                  </div>
                  <div className="IPT-modal-input-container">
                    <Label>Assigned To</Label>
                    {/* <Input disabled={isDisabled} className="ipt-model-input" id="agenda" onChange={e => setAssignedTo(e.target.value)} value={assignedTo} /> */}
                    <input
                      list="user-ids"
                      disabled={isDisabled}
                      className="ipt-model-input"
                      id="agenda"
                      onChange={e => setAssignedTo(e.target.value?.trim())}
                      value={assignedTo}
                    />
                    <datalist id="user-ids">
                      {props.userList.map(obj => {
                        return <option key={obj.user_id} value={obj.user_name} label={obj.user_email} />;
                      })}
                    </datalist>
                  </div>
                </div>
              </div>
              {props.modalType !== 'create' && (
                <div className={'IPT-comment-section'}>
                  <div className="IPT-comment-container">
                    <Label className="comment-label">Comments</Label>
                    <div className="comment-body">
                      <div className="IPT-comment-list">
                        {existingComments?.map((comment: any, index: any) =>
                          comment.user_comment !== undefined && comment.user_comment !== '' ? (
                            <div key={index} className="IPT-comment-item" data-initials={comment.initials} title={comment?.user_name}>
                              <div className="editor-content">{parse(comment.user_comment)}</div>
                              {props.modalType !== 'closed' && comment.user_id === currentUserId && !comment.isDayPassed ? (
                                <span
                                  key={'span' + index}
                                  onClick={e => removeComment(e, comment?.comment_id)}
                                  data-comment-id={comment?.comment_id}
                                  style={{ cursor: 'pointer' }}
                                >
                                  X
                                </span>
                              ) : null}
                            </div>
                          ) : null,
                        )}
                      </div>
                      {props.modalType !== 'closed' ? <span className="IPT-comment-list-separator">Use Shift + Enter to submit</span> : null}
                      <div className="IPT-comment-input-container">
                        {props.modalType !== 'closed' && (
                          <div className="IPT-comment-input">
                            <div className="comment-editor" id={props.modalType + 'comment'} contentEditable={!isDisabled} />
                            <Button
                              text=""
                              icon={<PlusCircleIcon height={28} width={28} color="secondary" />}
                              style={{ margin: '-34px 5px 0px 0px', float: 'right' }}
                              onClick={submitComment}
                              type="link"
                              loading={commentLoader}
                            ></Button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {isDisabled ? (
              <div className="">
                <Button
                  text="Close"
                  onClick={() => {
                    onModelClose();
                  }}
                  style={{ margin: '10px 0', float: 'right' }}
                  type="secondary"
                ></Button>
              </div>
            ) : (
              <div className="ipt-btn-section">
                <Button text="Cancel" onClick={canCelModal} type="secondary"></Button>
                <Button text="Submit" onClick={submitTopic} type="primary"></Button>
              </div>
            )}
          </div>
        )}
      </Modal>
    </>
  ) : null;
};
export default IptDashboardModel;
