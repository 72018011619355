interface RunIconPropsInterface {
  fill: string;
}

const RunIcon = ({ fill }: RunIconPropsInterface) => {
  return (
    <svg fill={fill} height="12px" width="12px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <g>
        <g>
          <path
            d="M500.203,236.907L30.869,2.24c-6.613-3.285-14.443-2.944-20.736,0.939C3.84,7.083,0,13.931,0,21.333v469.333
               c0,7.403,3.84,14.251,10.133,18.155c3.413,2.112,7.296,3.179,11.2,3.179c3.264,0,6.528-0.747,9.536-2.24l469.333-234.667
               C507.435,271.467,512,264.085,512,256S507.435,240.533,500.203,236.907z"
          />
        </g>
      </g>
    </svg>
  );
};
export default RunIcon;
