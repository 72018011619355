import React, { useState, useEffect, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { columnDefs, vpMappingDiffCol } from './peopleTableHeader';
import { ColDef } from 'ag-grid-community';
import { toast } from 'react-toastify';
import { apiResponse, rowSpanning } from '../../utility/commonMethods';
import './index.scss';

const ViewVPMapping: React.FC = () => {
  const [rowData, setRowData] = useState<any[]>([]);
  const [peopleColDefs, setpeopleColDefs] = useState([{ headerName: 'Login Name', field: 'login_name', tooltipField: 'login_name' }]);

  const defaultColDef: any = useMemo<ColDef>(() => {
    return {
      initialWidth: 200,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      sortable: true,
      wrapText: false,
      filter: true,
      filterParams: { maxNumConditions: 1 },
      resizable: true,
    };
  }, []);

  const getPeopleMappingData = async () => {
    try {
      const res = await apiResponse('get', 'get-people-mapping', ['view'], {});
      if (res?.data.status === 'SUCCESS') {
        if (res.data.data.length > 0) {
          const rowData = res.data.data.map((item: any) => {
            item.state = '';
            item.login_name = item.login_name.trim(); // remove white spaces in login_name from API
            return item;
          });
          setRowData(JSON.parse(JSON.stringify(rowData)));
        } else {
          toast.error('People VP Mapping Data not available!!');
        }
      } else {
        console.log(res);
      }
    } catch (error) {
      console.log(error);
      toast.error('Something Went Wrong!!');
    }
  };
  useEffect(() => {
    getPeopleMappingData();
    const login_name_col = {
      headerName: 'Login Name',
      field: 'login_name',
      lockPosition: 'left',
      cellClass: 'locked-col',
      tooltipField: 'login_name',
      sort: 'asc',
    };
    setpeopleColDefs([login_name_col, ...columnDefs]);
  }, []);

  return (
    <>
      <div className="mapping-table-container">
        <div className="ag-theme-alpine" style={{ height: '70vh', width: 'auto' }}>
          <AgGridReact
            rowData={rowData}
            columnDefs={peopleColDefs}
            defaultColDef={defaultColDef}
            overlayNoRowsTemplate='<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
          />
        </div>
      </div>
    </>
  );
};

export default ViewVPMapping;
