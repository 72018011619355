import AddUserLogs from '../../components/access-logs/addUserLogs';
import RemoveUserLogs from '../../components/access-logs/removeUserLogs';
// import ExternalAccessLog from '../../components/access-logs/externalAccessLog';
import { Tabs } from '@opsdti-global-component-library/amgen-design-system';

const AccessLogs = () => {
  return (
    <>
      <Tabs
        className=""
        items={[
          {
            children: <AddUserLogs />,
            key: 'User Addition Log',
            label: 'User Addition Log'
          },
          {
            children: <RemoveUserLogs />,
            key: 'User Removal Log',
            label: 'User Removal Log',
          },
        ]}
      />
    </>
  );
};

export default AccessLogs;
